var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"badge",style:({
    'border-color': _vm.$vuetify.theme.themes[_vm.theme].background,
  })},[_c('div',{staticClass:"topRight",style:({
      'top': -(_vm.size/2) + 'px',
      'right': -(_vm.size/2) + 'px',
      'height': _vm.size + 'px',
      'width': _vm.size + 'px',
    })},[_c('div',{staticClass:"border",style:({
        'border-color': _vm.$vuetify.theme.themes[_vm.theme].background,
        'height': (_vm.size-4) + 'px',
        'width': (_vm.size-4) + 'px',
      })},[_c('StatusIcon',{staticClass:"readyWrap",attrs:{"circle":"","spinner":"","type":_vm.type,"size":_vm.size-4}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }