var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"resourceCard mx-auto"},[_c('v-sheet',{staticClass:"resourceSheet pa-4",attrs:{"color":(_vm.$vuetify.theme.dark) ? '#272727' : _vm.color}},[_c('v-card-title',{staticClass:"text-h5",style:({
        color: (_vm.$vuetify.theme.dark) ? _vm.color : 'white'
      })},[_vm._v(" "+_vm._s(_vm.name)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":_vm.url}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1)]}}])},[_c('span',[_vm._v("Open logs for CRD")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"},on:{"click":_vm.downloadCRD}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("Download CRD")])]),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function () { this$1.$emit('unselectNode', {}); }}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1),_c('v-card-subtitle',[_c('div',{staticClass:"conditionChipListWrapper my-2"},_vm._l((_vm.conditions),function(condition,index){return _c('div',{key:index},[_c('v-tooltip',{attrs:{"top":_vm.scrollY <= 20,"bottom":_vm.scrollY > 20,"disabled":condition.status},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({class:{
                  'conditionChip': true,
                },attrs:{"active":"","link":"","outlined":_vm.$vuetify.theme.dark,"color":(_vm.$vuetify.theme.dark) ? _vm.getType(condition) : 'white',"text-color":(_vm.$vuetify.theme.dark) ? '' : _vm.getType(condition)},on:{"click":function($event){return _vm.selectCondition(index)}}},'v-chip',attrs,false),on),[_c('StatusIcon',{attrs:{"type":(condition.status) ? 'success' : condition.severity.toLowerCase(),"spinnerWidth":2,"left":""}}),_vm._v(" "+_vm._s(condition.type)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(condition.severity)+": "+_vm._s(condition.reason))])])],1)}),0),_c('div',{staticClass:"mt-4"},[_c('v-text-field',{attrs:{"label":"Search Custom Resource Fields","dark":"","flat":"","solo-inverted":!_vm.$vuetify.theme.dark,"solo":_vm.$vuetify.theme.dark,"hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline","color":(_vm.$vuetify.theme.dark) ? 'white' : _vm.color},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-checkbox',{attrs:{"dark":"","hide-details":"","label":"Case sensitive search","color":(_vm.$vuetify.theme.dark) ? 'white' : _vm.color},model:{value:(_vm.caseSensitive),callback:function ($$v) {_vm.caseSensitive=$$v},expression:"caseSensitive"}})],1)])],1),_c('v-card-text',[_c('v-treeview',{staticClass:"text-wrap",attrs:{"hoverable":"","items":_vm.items,"search":_vm.search,"filter":_vm.filter,"open":_vm.open,"active":_vm.active,"activatable":"","rounded":""},on:{"update:open":function($event){_vm.open=$event},"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
                var item = ref.item;
return [_c('span',{ref:item.id,staticClass:"text-wrap"},[_vm._v(_vm._s(item.name))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }