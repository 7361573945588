var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"tree-container"},[_c('svg',{ref:"svg",staticClass:"svg vue-tree",style:(_vm.initialTransformStyle)}),_c('div',{ref:"domContainer",staticClass:"dom-container",style:(_vm.initialTransformStyle)},[_c('transition-group',{attrs:{"name":"tree-node-item","tag":"div"}},_vm._l((_vm.nodeDataList),function(node,index){return _c('div',{key:node.data._key,staticClass:"node-slot",style:({
          left: _vm.formatDimension(
            _vm.direction === _vm.DIRECTION.VERTICAL ? node.x : node.y
          ),
          top: _vm.formatDimension(
            _vm.direction === _vm.DIRECTION.VERTICAL ? node.y : node.x
          ),
          width: _vm.formatDimension(_vm.config.nodeWidth),
          height: _vm.formatDimension(_vm.config.nodeHeight)
        }),on:{"click":function($event){return _vm.onClickNode(index)}}},[_vm._t("node",function(){return [_c('span',[_vm._v(_vm._s(node.data.value))])]},{"node":node.data,"collapsed":node.data._collapsed})],2)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }