var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"targetTreeWrapper"},[_c('vue-tree',{ref:"tree",attrs:{"id":"resourceTree","dataset":_vm.treeData,"config":_vm.treeConfig,"collapse-enabled":true,"linkStyle":(_vm.store.straightLinks) ? 'straight' : 'curve'},on:{"scale":function (val) { return _vm.$emit('scale', val); }},scopedSlots:_vm._u([{key:"node",fn:function(ref){
var node = ref.node;
var collapsed = ref.collapsed;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"card-wrap shadow"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card',{staticClass:"node mx-auto transition-swing",style:((_vm.$vuetify.theme.dark) ? {
                  //background: $vuetify.theme.themes[theme].legend[node.provider].background + (hover ? '55' : '44'),
                  //color: hover ? '#fff' : $vuetify.theme.themes[theme].legend[node.provider].text,
                  'background-color': hover ? '#383838' : '#272727',
                  'color': _vm.$vuetify.theme.themes[_vm.theme].legend[node.provider],
                  // 'border-color': $vuetify.theme.themes[theme].legend[node.provider],
                } : {
                  'background-color': _vm.$vuetify.theme.themes[_vm.theme].legend[node.provider],
                }),attrs:{"dark":"","elevation":hover ? 6 : 3},on:{"click":function($event){return _vm.selectNode(node)}}},[_c('p',{staticClass:"kind font-weight-medium text-truncate"},[_vm._v(_vm._s((node.collapsible) ? node.displayName : node.kind))]),(!node.collapsible)?_c('p',{staticClass:"name font-italic text-truncate"},[_vm._v(_vm._s(node.displayName))]):(collapsed)?_c('v-icon',{staticClass:"chevron",style:((_vm.$vuetify.theme.dark) ? {
                    'color': _vm.$vuetify.theme.themes[_vm.theme].legend[node.provider],
                  } : null),attrs:{"size":"18"}},[_vm._v("mdi-chevron-down")]):_c('v-icon',{staticClass:"chevron",style:((_vm.$vuetify.theme.dark) ? {
                    'color': _vm.$vuetify.theme.themes[_vm.theme].legend[node.provider],
                  } : null),attrs:{"size":"18"}},[_vm._v("mdi-chevron-up")])],1)],1),(node.hasReady)?_c('StatusBadge',{attrs:{"type":(node.ready) ? 'success' : node.severity.toLowerCase(),"size":18}}):_vm._e()],1)]}}],null,true)})]}}])}),_c('div',{staticClass:"legend"},[_c('v-card',{staticClass:"legend-card"},_vm._l((_vm.legend),function(displayName,provider){return _c('div',{key:provider,staticClass:"legend-entry"},[_c('div',{staticClass:"legend-entry-content"},[_c('v-icon',{staticClass:"legend-entry-icon",attrs:{"color":_vm.$vuetify.theme.themes[_vm.theme].legend[provider]}},[_vm._v(" mdi-square-rounded ")]),_c('div',{staticClass:"legend-entry-text"},[_vm._v(_vm._s(displayName))])],1)])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }