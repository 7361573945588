var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"appBar","app":"","color":(_vm.$vuetify.theme.dark ? null: 'primary'),"dark":""}},[(_vm.showBack)?_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","text":""},on:{"click":function () { _vm.router.back() }}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-left ")])],1):_c('v-app-bar-nav-icon',{staticClass:"ma-2"}),_c('v-toolbar-title',{staticClass:"text-no-wrap pa-0"},[_vm._v(_vm._s(_vm.title))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.$emit('reload', true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s("mdi-refresh")+" ")])],1)]}}])},[_c('span',[_vm._v("Reload resources")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.$emit('zoomOut', true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s("mdi-minus")+" ")])],1)]}}])},[_c('span',[_vm._v("Zoom out")])]),(_vm.scaleIcon)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-"+_vm._s(_vm.scaleIcon))]):_c('span',[_vm._v(_vm._s(_vm.scale))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.$emit('zoomIn', true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s("mdi-plus")+" ")])],1)]}}])},[_c('span',[_vm._v("Zoom in")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.$emit('showSettings', true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-cog ")])],1)]}}])},[_c('span',[_vm._v("Show settings")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }