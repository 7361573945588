var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"overview"}},[_c('AppBar',{attrs:{"title":'Logs: ' + _vm.kind + '/' + _vm.name,"showBack":true,"scale":String(_vm.fontSize),"scaleIcon":"format-size"},on:{"reload":function($event){return _vm.fetchLogs(_vm.forceReload=true);},"zoomIn":function () { _vm.fontSize += 1 },"zoomOut":function () { 
      if (_vm.fontSize > 1) {
        _vm.fontSize -= 1;
      }
    },"showSettings":function () { _vm.showSettingsOverlay = true }}}),_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.logsReady),expression:"logsReady"}],staticClass:"logs-card",attrs:{"id":"chartLoadWrapper","dark":""}},[_c('v-container',{staticClass:"log-container ma-0 px-0 py-2",attrs:{"dark":"","fluid":""}},[_c('DynamicScroller',{staticClass:"scroller",attrs:{"items":_vm.logData,"min-item-size":2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
    var active = ref.active;
return [_c('DynamicScrollerItem',{attrs:{"item":item,"active":active,"size-dependencies":[
                item.message ],"data-index":index}},[_c('v-row',{attrs:{"rounded":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","rounded":""}},[_c('highlightjs',{staticClass:"wrap pa-0 ml-2 mr-2 my-0",style:(_vm.dynamicFontSize),attrs:{"language":"json","code":item.message}})],1)],1)],1)]}}])})],1)],1),(!_vm.logsReady)?_c('div',{staticClass:"spinner",attrs:{"id":"resourceTree"}},[_c('v-progress-circular',{attrs:{"size":50,"width":5,"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('AlertMessage',{attrs:{"type":_vm.alertType,"message":_vm.errorMessage},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}}),_c('v-overlay',{attrs:{"id":"settingsOverlay","value":_vm.showSettingsOverlay,"z-index":"99999"}},[_c('SettingsCard',{staticClass:"settingsCard",attrs:{"version":_vm.gitVersion},on:{"close":function () { _vm.showSettingsOverlay = !_vm.showSettingsOverlay }}})],1),_c('ScrollButton')],1)}
var staticRenderFns = []

export { render, staticRenderFns }