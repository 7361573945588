import { render, staticRenderFns } from "./VueTree.vue?vue&type=template&id=5e220162&scoped=true"
import script from "./VueTree.vue?vue&type=script&lang=js"
export * from "./VueTree.vue?vue&type=script&lang=js"
import style0 from "./VueTree.vue?vue&type=style&index=0&id=5e220162&prod&lang=less"
import style1 from "./VueTree.vue?vue&type=style&index=1&id=5e220162&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e220162",
  null
  
)

export default component.exports